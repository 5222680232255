require('magnific-popup')

module.exports = {

  init: () => {
    $('.video').magnificPopup({
      type: 'iframe'
    });
  }

}
