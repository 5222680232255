﻿module.exports = {

    init: () => {
        $('.newsletterform').submit(function () {
            var values = [];

            $(this).find('input[type="checkbox"][name="newsletterCategories"]:checked').each(function () {
                values.push(this.value);
            })

            $(this).find('input[name="lists"]').attr('value', values.join(','))
        })
    }
}