module.exports = {
    init: () => {
        $('#customerType').on('change', function () {
            var customerType = $(this).val()
            switch (customerType) {
                case 'privat':
                    $('#customerInfoBusiness, #customerInfoPublic').addClass(
                        'grid-hidden'
                    )
                    $('#customerInfoPrivate').removeClass('grid-hidden')
                    return

                case 'erhverv':
                    $('#customerInfoPrivate, #customerInfoPublic').addClass(
                        'grid-hidden'
                    )
                    $('#customerInfoBusiness').removeClass('grid-hidden')
                    return

                case 'offentlig':
                    $('#customerInfoPrivate, #customerInfoBusiness').addClass(
                        'grid-hidden'
                    )
                    $('#customerInfoPublic').removeClass('grid-hidden')
                    return
            }
        })

        $('#customerInfoBusinessCountry').on('change', function () {
            var country = $(this).val()
            switch (country) {
                case 'Sverige':
                    $('#customerInfoBusiness .countryInfo').removeClass(
                        'grid-hidden'
                    )
                    return
                case 'Norge':
                    $('#customerInfoBusiness .countryInfo').removeClass(
                        'grid-hidden'
                    )
                    return
                default:
                    $('.countryInfo').addClass('grid-hidden')
                    return
            }
        })
    }
}
