module.exports = {

  init: () => {
    $('.details-basket-count-action').click(function () {

      const $button = $(this)
      const oldValue = $button.parent().find('input').val()
      let newValue = 0

      if ($button.hasClass('details-basket-count-action--up')) {
        newValue = parseFloat(oldValue) + 1
      } else if (oldValue > 0) {
        newValue = parseFloat(oldValue) - 1
      } else {
        newValue = 0
      }

      $button.parent().find('input').val(newValue)
      return false
    })

  }
}
