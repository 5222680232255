require("jquery-ui/ui/widgets/slider")


module.exports = {

  init: () => {

    const sliderElement = $('.filter-slider')
    const minValue = sliderElement.data('min')
    const maxValue = sliderElement.data('max')
    const initialValues = [sliderElement.data('init-min'), sliderElement.data('init-max')]

    sliderElement.slider({
      range: true,
      min: minValue,
      max: maxValue,
      values: initialValues,
      slide: function (event, ui) {
        updateValue(ui)
      },
      change: function (event, ui) {
        updateValue(ui)
      },
      create: function (event, ui) {
        $.each(initialValues, function (i, v) {
          updateValue({
            value: v,
            handle: $('.ui-slider-handle').eq(i)
          })
        })

      }
    })

    function updateValue(ui) {
      $(ui.handle).attr('data-value', ui.value + ' kr')
    }

    $('.filter-slider-reset').click(function () {
      $(this).closest('.filter').find('.filter-slider').slider('values', initialValues)
      return false
    })
  }
}
