module.exports = {
  init: () => {
    const trigger = $('.login-teaser')
    const overlay = $('.login-overlay') // todo: what do we use this variable for?

    trigger.click(function () {
      $(this).closest('.login').addClass('login--active')
    })

    $('html, body').on('touchstart click', function (e) {
      if ($(e.target).closest('.login--active').length === 0) {
        $('.login').removeClass('login--active')
      }
    })

  }
}
