window.initAutocomplete = function () {
    $('.autocomplete-key').each(function (index, inputField) {
        var autocomplete = new google.maps.places.Autocomplete(inputField, {
            types: ['address'],
            componentRestrictions: {
                country: ['DK', 'SE', 'NO', 'GL']
            },
            fields: ['geometry', 'address_components']
        })
        autocomplete.inputField = inputField
        autocomplete.addListener('place_changed', onPlaceChanged)

        google.maps.event.addDomListener(inputField, 'keydown', function (
            event
        ) {
            if (event.keyCode === 13) {
                event.preventDefault()
            }
        })
    })
}

function getAddressComponentByType (
    addressComponents,
    addressComponentTypeName
) {
    return addressComponents.find(function (addressComponent) {
        return addressComponent.types.find(function (addressComponentType) {
            return addressComponentType === addressComponentTypeName
        })
    })
}

function onPlaceChanged () {
    var place = this.getPlace()
    if (place.geometry) {
        $('.autocomplete-key, .autocomplete-zip, customerInfoPrivateCity').val(
            ''
        )
        var streetAddressComponent = getAddressComponentByType(
            place.address_components,
            'route'
        )
        if (streetAddressComponent != null) {
            $('.autocomplete-key').val(streetAddressComponent.long_name)
        }
        var streetNumberComponent = getAddressComponentByType(
            place.address_components,
            'street_number'
        )
        if (streetNumberComponent != null) {
            $('.autocomplete-key').val(
                $(this.inputField).val() + ' ' + streetNumberComponent.long_name
            )
        }
        var countryAddressComponent = getAddressComponentByType(
            place.address_components,
            'country'
        )
        if (countryAddressComponent != null) {
            $('.autocomplete-country').each(function (index, element) {
                var selectJquery = $(element)
                var selectedOptionValue = selectJquery
                    .find(
                        'option[data-maps-id="' +
                            countryAddressComponent.short_name +
                            '"]'
                    )
                    .val()
                selectJquery.val(selectedOptionValue).trigger('chosen:updated')
            })
        }

        var postalCodeAddressComponent = getAddressComponentByType(
            place.address_components,
            'postal_code'
        )
        if (postalCodeAddressComponent != null) {
            $('.autocomplete-zip').val(postalCodeAddressComponent.long_name)
        }

        var localityAddressComponent = getAddressComponentByType(
            place.address_components,
            'locality'
        )
        if (localityAddressComponent == null) {
            localityAddressComponent = getAddressComponentByType(
                place.address_components,
                'postal_town'
            )
        }

        if (localityAddressComponent != null) {
            $('.autocomplete-city').val(localityAddressComponent.long_name)
        }
    }
}
