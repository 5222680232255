require('chosen-js')

module.exports = {
  chosenBrowserIsSupported: function () {
    if (/iP(od|hone)/i.test(window.navigator.userAgent)) {
      return false
    }
    if (/Android/i.test(window.navigator.userAgent)) {
      if (/Mobile/i.test(window.navigator.userAgent)) {
        return false
      }
    }
    if (/IEMobile/i.test(window.navigator.userAgent)) {
      return false
    }
    if (/Windows Phone/i.test(window.navigator.userAgent)) {
      return false
    }
    if (/BlackBerry/i.test(window.navigator.userAgent)) {
      return false
    }
    if (/BB10/i.test(window.navigator.userAgent)) {
      return false
    }
    if (window.navigator.appName === 'Microsoft Internet Explorer') {
      return document.documentMode >= 8
    }

    return true
  },

  initializeChosen: function () {
    const $selects = $('select')

    $selects.chosen({
      disable_search: true,
      inherit_select_classes: true
    })

    if (!this.chosenBrowserIsSupported()) {
      $selects.each(function () {
        let placeholder = $(this).data('placeholder')

        let option = $(this).find('option').first()
        option.val(0)
        option.text(placeholder)
        option.attr('disabled', true)
        option.attr('selected', 'selected')
      })
    }
  }
}
