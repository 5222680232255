let oldHeight = -1

const onResize = () => {
  const footer = $('footer.footer')
  const footerHeight = footer.innerHeight()
  if (oldHeight === footerHeight) {
    return // skip if the height has not changed since last time
  }

  $('.sticky-footer-page-content-wrapper').css({ marginBottom: +(footerHeight * -1) + 'px' })
  $('#sticky-footer-styling').remove()

  $(`<style id="sticky-footer-styling">.sticky-footer-page-content-wrapper::after{height:${footerHeight}px !important}</style>`).appendTo('head')
}

module.exports = {
  onResize: onResize,

  init: () => {
    $(window).resize(onResize)
    onResize()
  }
}
