module.exports = {

  init: () => {
    $('.filter-trigger').click(function () {
      if($('.filter-wrapper').hasClass('filter-wrapper--active')) {
        $('.filter-wrapper').removeClass('filter-wrapper--active')
        $(this).text('Vis filtre')
      }else{
        $('.filter-wrapper').addClass('filter-wrapper--active')
        $(this).text('Skjul filtre')
      }


      return false
    })

  }
}
