require('responsive-tabs')

module.exports = {

  init: () => {
    $('.contentpanel').responsiveTabs({
      startCollapsed: 'accordion'
    })
  },

  test: 4

}
