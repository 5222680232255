require("jquery-validation");
require("jquery-validation/dist/localization/messages_da");

module.exports = {
    init: () => {
        const forms = $("form.validate");
        forms.each((i, form) => {
            const $form = $(form);

            $form.validate({
                errorClass: "error input-error",
                errorElement: "span",
                ignore: "", // Don't ignore hidden fields (chosen)

                errorPlacement: ($error, $element) => {
                    let $errorElement = $element;
                    if ($element.is("select")) {
                        $errorElement = $element.siblings(".chosen-container");
                    }

                    if ($element.attr("type") === "checkbox") {
                        $error.insertBefore($errorElement);
                    } else {
                        $error.insertAfter($errorElement);
                    }

                    $errorElement.addClass("error");
                },

                success: (error, element) => {
                    const $element = $(element);

                    let $errorElement = $element;
                    if ($element.is("select")) {
                        $errorElement = $element.siblings(".chosen-container");
                    }

                    error.remove();
                    $errorElement.removeClass("error");
                },

                rules: {
                    password: {
                        minlength: 6,
                    },
                    confirmPassword: {
                        minlength: 6,
                        equalTo: $form.find('input[name="password"]'),
                    },
                    newsletterCategories: {
                        required: true,
                    },
                },
                messages: { newsletterCategories: "Vælg mindst ét nyhedsbrev" },
            });
        });
    },
};
