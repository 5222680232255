require("jquery-ui/ui/position")

module.exports = {

  init: () => {

    initMenu()

    $(window).resize(function () {
      initMenu()
    })

    function initMenu() {
      $.each($('.submenu-item-box'), function (index, value) {
        $(this).position({
          my: 'left top',
          at: 'left bottom',
          of: $(this).closest('.submenu-item'),
          collision: 'fit',
          within: 'body'
        })
      })
    }
  }
}
