require('trunk8')

module.exports = {

  init: () => {
    $('.halloffame-item-title').trunk8({
      lines: 2
    })
  }

}
