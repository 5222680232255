module.exports = {
    init: () => {
        const trigger = $(".mobilemenu-links-pagelink--login");

        trigger.on("click", function () {
            $(this)
                .closest("div")
                .find(".mobilemenu-login-wrapper")
                .toggleClass("mobilemenu-login-wrapper--active");

            $(".mobilemenu").scrollTop($(".mobilemenu").height());
            return false;
        });
    },
};
