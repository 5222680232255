module.exports = {

  init: () => {
    const $selects = $('select.dropdown-link')

    $selects.on('change', function () {
      window.location.href = $(this).val()
    })
  }

}
