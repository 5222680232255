require('./_config')

global.jQuery = require('jquery')
global.$ = jQuery

require('./_address')

const ElementQueries = require('css-element-queries/src/ElementQueries')
//const cart = require('./_cart')
const validate = require('./_validate')
const submit = require('./_submit')
const mobileMenu = require('./_mobile-menu')
const stickyFooter = require('./_sticky-footer')
const chosen = require('./_chosen')
const login = require('./_login')
const halloffame = require('./_halloffame-item')
const submenu = require('./_submenu')
const filterSlider = require('./_filter-slider')
const filterWrapper = require('./_filter-wrapper')
const detailsBasket = require('./_details-basket')
const contentpanel = require('./_contentpanel')
const issuu = require('./_issuu')
const popup = require('./_popup')
const video = require('./_video')
const dropdownLink = require('./_dropdown-link')
const postalCodeInserter = require('./_postalcode-inserter')
const autosubmit = require('./_autosubmit')
const newsletterform = require('./_newsletterform')
const loginMobile = require('./_login-mobile')
const customerinfoform = require('./_customerinfoform')
const matchheight = require('./_matchheight')

$(() => {
    require('./_filter').init()
    //cart.init()
    validate.init()
    submit.init()
    mobileMenu.init()
    stickyFooter.init()
    chosen.initializeChosen()
    login.init()
    halloffame.init()
    submenu.init()
    filterSlider.init()
    filterWrapper.init()
    detailsBasket.init()
    contentpanel.init()
    issuu.init()

    popup.init()
    video.init()
    dropdownLink.init()
    postalCodeInserter.init()
    autosubmit.init()
    newsletterform.init()
    loginMobile.init()
    customerinfoform.init()
    matchheight.init()
})

ElementQueries.listen()
