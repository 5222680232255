module.exports = {
    init: () => {
        $(".header-burger").click(function (event) {
            $(".header-burger").toggleClass("header-burger--active");
            $(".mobilemenu").toggleClass("mobilemenu--active");
            if ($(".mobilemenu").hasClass("mobilemenu--active")) {
                $("body").css({ overflow: "hidden" });
            } else {
                $("body").css({ overflow: "visible" });
            }
            return false;
        });

        let mobileMenuTimeout;

        $(".mobilemenu-links-menulink").click(function (event) {
            $(".mobilemenu-links--submenu").css("display", "none");
            clearTimeout(mobileMenuTimeout);
            $("#" + $(this).data("target")).css("display", "inline-block");
            $(".mobilemenu-links-container").toggleClass(
                "mobilemenu-links-container--opensubmenu"
            );
            return false;
        });

        $(".mobilemenu-links-backlink").click(function (event) {
            $(".mobilemenu-links-container").removeClass(
                "mobilemenu-links-container--opensubmenu"
            );
            mobileMenuTimeout = setTimeout(function () {
                $(".mobilemenu-links--submenu").css("display", "none");
            }, 300);
            return false;
        });
    },
};
